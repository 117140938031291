<template>
  <div>
    <vx-card title="Expenses Budget This Month" :is-refresh="isLoading">
      <!-- SLOT = ACTIONS -->
<!--      <template slot="actions">-->
<!--        <change-time-duration-dropdown />-->
<!--      </template>-->

      <!-- CHART -->
      <template slot="no-body">
        <div class="mt-10">
          <vue-apex-charts type="radialBar" height="290" :options="chartOptions" :series="chartData.series" />
        </div>
      </template>

      <!-- DATA -->
      <div class="flex justify-between text-center mt-6" slot="no-body-bottom">
        <div class="w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0 border-l-0">
          <p class="mt-4">Reached</p>
          <p class="mb-4 text-1xl font-semibold">{{ chartData.reachedTotal | idr(0, true) }}</p>
        </div>
        <div class="w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0">
          <p class="mt-4">Remaining</p>
          <p class="mb-4 text-1xl font-semibold">{{ (chartData.budgetTotal - chartData.reachedTotal) | idr(0, true) }}</p>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import VueApexCharts from 'vue-apexcharts'
import _ from 'lodash'

export default {
  name: 'StatisticsCard',
  components: {
    VueApexCharts
  },
  mounted () {
    this.initData()
  },
  computed: {
    payload () {
      return {
        budget: _.find(),
        reached: 0,
        percentage: 0,
        series: []
      }
    }
  },
  data () {
    return {
      isLoading: false,
      params: {},
      data: {
        queryResult: []
      },
      chartData: {
        budgetTotal: 0,
        reachedTotal: 0,
        percentage: 0,
        series: [0]
      },
      chartOptions: {
        plotOptions: {
          radialBar: {
            size: 110,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%'
            },
            track: {
              background: '#bfc5cc',
              strokeWidth: '50%'
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: 18,
                color: '#99a2ac',
                fontSize: '4rem'
              }
            }
          }
        },
        colors: ['#F97794', '#b8c2cc'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#7367F0', '#b8c2cc'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        chart: {
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1
          }
        }
      }
    }
  },
  methods: {
    initData () {
      this.getData()
    },

    getData () {
      this.isLoading = true

      const reportId = 72
      const params = { params: this.params }
      return ReportRepository.templateData(reportId, params)
        .then(response => {
          this.data = response.data
          this.chartData = response.data.chartData
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
